.container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.header {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-height: 55px;
}

.chatInput {
  background-color: #d6d6d6;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.commentInput {
  background-color: #d6d6d6;
  height: 120px;
  padding: 5px;
  outline: none;
  border-radius: 8px;
  border: none;
  vertical-align: text-top;
  display: flex;
  width: 60%;
  background-color: white;
}

.myMessage {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  background-color: #7a63ff;
  border-radius: 8px;
  width: 70%;
  margin-top: 12px;
  align-self: flex-end;
}

.otherMessage {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background-color: #d6d6d6;
  border-radius: 8px;
  width: 70%;
  margin-top: 12px;
}

.otherMessageText {
  padding-top: 8px;
  margin-left: 8px;
  color: black;
  word-wrap: break-word;
  padding-right: 8px;
}

.myMessageText {
  padding-top: 8px;
  margin-left: 8px;
  color: white;
  word-wrap: break-word;
  padding-right: 8px;
}

.otherMessageTime {
  align-self: flex-end;
  margin-right: 8px;
  padding-bottom: 8px;
  color: #7b7b7b;
  margin-top: 8px;
  font-size: 0.6rem;
}

.messageTime {
  align-self: flex-end;
  margin-right: 8px;
  padding-bottom: 8px;
  color: white;
  margin-top: 8px;
  font-size: 0.6rem;
}

.buttonIcon {
  width: 35px;
  height: 35px;
}

.content {
  display: flex;
  align-self: stretch;
  height: max-content;
  flex: 14;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.chatHeader {
  display: flex;
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  border-bottom-color: #f5f5f5;
}

.chatContainer {
  flex: 1;
  background-color: white;
  border-radius: 8px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.contentLeft {
  display: flex;
  /* flex: 4; */
  flex-direction: column;
  /* height: 95%; */
  /* max-width: 400px; */
  /* height: 90%; */
  height: 91%;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.contentRight {
  flex: 4;
  height: 95%;
  flex-direction: column;
  /* overflow: scroll; */
  overflow: hidden;
}

.contentMid {
  position: relative;
  display: flex;
  height: 100%;
  /* flex: 5; */
  width: 100%;
  justify-content: center;
  align-items: center;
}

.messageInput {
  margin-left: 8px;
  margin-right: 8px;
  border: none;
  border-radius: 12px;
  height: 30px;
  font-size: 16px;
  color: #7a63ff;
  flex: 1;
  display: flex;
  background-color: white;
  padding-right: 4px;
  outline: none;
  /* max-width: 500px; */
  width: 100%;
}

.shareRemote {
  z-index: 999;
  width: 12vh;
  height: 18vh;
  background-color: white;
  position: absolute;
  overflow: hidden;
  bottom: 6%;
  left: 6%;
  border-radius: 10px;
  overflow: hidden;
}

.screenShare {
  position: relative;
  border-radius: 10px;
  background-color: white;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.remote {
  /* min-width: 480px; */
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  width: 90%;
  height: 95%;
}

.local {
  z-index: 999;
  width: 13vh;
  height: 20vh;
  background-color: white;
  position: absolute;
  bottom: 6%;
  right: 6%;
  border-radius: 10px;
  overflow: hidden;
}

.closeButton {
  cursor: pointer;
  width: 65px;
  height: 65px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #ff3c3c;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #7a63ff;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-width: 300px;
}

@media only screen and (max-width: 480px) {
  .buttonBreak {
    width: 45px !important;
    height: 45px !important;
  }

  .closeButton {
    width: 50px !important;
    height: 50px !important;
  }

  .buttonIcon {
    width: 30px !important;
    height: 30px !important;
  }
}

.buttonBreak {
  cursor: pointer;
  width: 55px;
  height: 55px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #7a63ff;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-width: 300px;
}

.fotterLeft {
  display: flex;
  /* flex: 10; */
  justify-content: center;
  align-items: center;
}

.fotterRight {
  display: flex;
  /* flex: 3; */
  justify-content: center;
  align-items: center;
}

.fotterMid {
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.suggestion {
  width: 90%;
  display: flex;
  border-radius: 10px;
  margin-left: 3%;
  height: 75px;

  margin-bottom: 15px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}

.mobileSuggestContainer {
  z-index: 1000;
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 130px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0px -5px 30px;
}

.suggestCont {
  display: flex;
  overflow: scroll;
  /* flex: 2.2; */
  padding-top: 15px;
}

.suggestCont span {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selffixIcon {
  height: 30px;
  margin-left: 50px;
}

.mobileSuggestContainer::-webkit-scrollbar {
  display: none;
}

.suggestCont::-webkit-scrollbar {
  display: none;
}
